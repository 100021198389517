import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Upcoming Products`}</h2>
    <ProjectCard id="qKarlaKaumudi" title="KarlaKaumudi Akhada" //bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
    bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" //bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
    mdxType="ProjectCard">
Karlakattai is an ancient warrior (Kshatriya) art. 
Apparently, our ancestors had solved the fitness puzzle making it exciting and 
accessible for people from all walks of life. We teach this art online.
      <div style={{
        "backgroundColor": "rgba(40, 0, 0, 0.8)",
        "border": "1px solid white",
        "borderRadius": "5px",
        "padding": "5px",
        "margin": "2px"
      }}>
        <a href="https://forms.gle/cnMn9o1qN9eCp18Y6">Register for the upcoming free workshop</a>
      </div>
or
  <div style={{
        "backgroundColor": "rgba(40, 0, 0, 0.8)",
        "border": "1px solid white",
        "borderRadius": "5px",
        "padding": "5px",
        "margin": "2px"
      }}>
        <a href="https://forms.gle/wsAZeeZ4dgNgUGh68">Register for the three month foundational course</a>
      </div>
    </ProjectCard>
    <ProjectCard title="KaPi" //bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
    bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
Wearable tech for Karlakattai / Mudgar. The oldest fitness knowledge system meets the present day wearable and app world.
Ver. 1 coming this December.
    </ProjectCard>
    <ProjectCard title="DakhsiLipi" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" //bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
    mdxType="ProjectCard">
Computer programming in Bhartiya Bhasha for clear study of oldest algorithms written in various
Ganit Shastras like ChandShastra, SulbhaSutra, Aryabhatiya etc.
      <a href="https://github.com/vagambhrini/dakshilipy"> Github project </a>
    </ProjectCard>
    <ProjectCard title="Ganit Granth Samganan Vidhi in Computer languages." //bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
    //bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
    bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
We are working on getting all our Ganit Shastra algorithms in computer programming notebooks to be
studied & researched in modern ways.
      <a href="https://github.com/vagambhrini/dakshilipy/tree/main/ग्रंथ/छंदशास्त्र"> Github project </a>
    </ProjectCard>
    <blockquote>
      <p parentName="blockquote">{`... and more`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{` Stay tuned for the updates.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      