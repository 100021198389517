import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Join Us`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Our Vision: We believe that the cycle of questioning and learning is essential for
well-being. Our products are a result of asking hard questions about the past,
present and future of learning and wellbeing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Our Process: We do it by adding the lost gems to a knowledge system,
sharpening them with current technologies and directing them for future
relevance.`}</p>
      </li>
    </ul>
    <p>{`If our products and vision interest you, let us team-up and collaborate.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      